.survey-description {
  font-size: 16px;
}

.form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px;
  gap: 100px;

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .container {
      max-width: 100%;
      padding: 0 20px;
      margin: 0 auto;
      text-align: center;

      @media (max-width: 768px) {
        .logos {
          display: flex;
          flex-direction: column;
          gap: 0;
        }

        .logos img {
          max-width: 240px; /* Adjust this value as needed */
          height: auto;
        }
        h1 {
          font-size: 15px;
        }
        h2 {
          font-size: 12px;
        }
      }
    }

    .logos {
      display: flex;
      justify-content: space-between;
      gap: 100px;
      margin-bottom: 20px;

      img {
        object-fit: cover;
        height: 150px;
        padding: 50px;
        width: auto;
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      font-family: "Raleway", sans-serif;

      h1 {
        width: 100%;
        text-align: center;
      }
    }
    .subTitle {
      text-align: center;
      font-family: "Raleway", sans-serif;
    }

    .options-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 50px;
      border-top: 1px solid #436ac5;
      padding-top: 40px;

      .option-card {
        margin: 0 1rem;
        text-align: center;
        font-size: 20px;
        flex: 1;

        h2 {
          margin-bottom: 0.5rem;
          font-weight: bold;
          font-family: "Open-sans", sans-serif;
        }

        p {
          margin-bottom: 1rem;
          font-weight: 600;
          font-family: "Raleway", sans-serif;
        }

        button {
          color: white;
          width: 120px;
          height: 40px;
          margin-bottom: 100px;
          padding: 10px;
          background-color: #436ac5;
          color: #fff;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #0e0a61;
          }
        }
      }

      .option-card:first-child {
        button {
          margin-top: 21px;
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;

        .option-card {
          font-size: 16px;
        }

        .option-card h2 {
          font-size: 15px;
        }

        .option-card p {
          font-size: 14px;
        }
        .option-card button {
          font-size: 10px;
          width: 90px;
        }
      }
    }
  }
}
