@media print {
  .no-print {
    display: none !important;
  }
}

.survey-container1 {
  max-width: 1100px;
  margin: 0 auto;

  .patient {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .id {
      margin: 20px;
      width: 50%;
      margin-right: 40px;

      p {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      textarea {
        width: 100%;
        height: 140px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        resize: none;
      }
    }

    .date {
      width: 40%;

      textarea {
        width: 100%;
        max-width: 200px;
        padding: 10px;
        resize: vertical;
      }

      p {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      input[type="date"] {
        width: 80%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
    }
  }

  .button_container {
    position: relative;
    .back {
      position: absolute;
      margin: 0 16px;
      font-size: 0.75em;
      font-weight: bold;
      line-height: var(--base-line-height, 1.8em);
      border: none;
      min-width: 100px;
      cursor: pointer;
      padding: 0.3em 2em;
      border-radius: 2px;
      background-color: #1ab394;
      color: white;
      bottom: 25px;
    }
  }

  .sv_qstn {
    font-size: 19px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      font-size: 15px;
    }
  }
  .sv_complete_btn {
    background: red;
  }

  .sv_qstn_error {
    font-weight: bold;
  }

  .copyright {
    font-size: 14px;
    color: #999;
    margin-top: 20px;
    text-align: center;
    padding: 10px 0;

    &:hover {
      color: #555;
    }
    border-bottom: 1px solid #ddd;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }

  .scores {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #edf3f8;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 0px 20px;
    .totalScore {
      font-size: 24px;
      font-weight: bold;
      font-family: "Raleway", sans-serif;
    }
    .values {
      font-size: 20px;
      font-family: "Raleway", sans-serif;
      text-align: center;
    }
  }
}

.IADL {
  background-color: #23568b;
  color: white;
  padding: 1rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 2rem;
  margin-top: 20px;

  h1 {
    margin: 0;
    text-align: center;
  }
}
.desc {
  font-size: 2em;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
  padding: 5px;
  background-color: white;
  font-family: "Open Sans", sans-serif;
}
