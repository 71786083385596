.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(214, 217, 236);
  color: black;
  width: 100%;
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0), 5px 0 5px rgba(0, 0, 0, 0),
    5px 0 5px rgba(0, 0, 0, 0), 5px 0 5px rgba(0, 0, 0, 0.5);

  .footerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footerLogo {
      margin: 80px;

      .footerImg {
        width: 200px;
        border-radius: 10px;
        box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1), 5px 0 5px rgba(0, 0, 0, 0.1);
      }
    }

    .footerText {
      display: flex;

      .info {
        font-size: 14x;
        font-family: "Raleway", sans-serif;
        line-height: 2px;
        font-weight: bold;
        margin: 20px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 5px; /* Add padding for better spacing */
  }

  .footerContent {
    flex-direction: column;
  }

  .footerLogo {
    margin: 10px 0;
  }

  .footerText {
    display: flex;
    flex-direction: column;
    font-size: 11px;
  }
}
