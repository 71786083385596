.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  background-color: rgb(242, 244, 252);
  width: 100%;
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1), 5px 0 5px rgba(0, 0, 0, 0.1);

  .navbarLogos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 400px;
    flex: 1;

    .navbarLogo {
      width: 200px;
    }
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    display: flex;
    flex-direction: column;
  }

  .navbarLogos {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .navbarLogo {
    width: 100px;
    margin: 10px 0;
  }
}
