.survey-container2 {
  max-width: 1450px;
  margin: 0 auto;

  .patient {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 40px;
    .id {
      margin-right: 40px;
      p {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      textarea {
        width: 100%;
        height: 140px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        resize: none;
      }
    }

    .date {
      min-width: 40%;

      textarea {
        width: 100%;
        max-width: 200px;
        padding: 10px;
        resize: vertical;
      }

      p {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      input[type="date"] {
        width: 50%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
    }
  }

  h1 {
    font-size: 2em;
    font-weight: 600;
    margin: 0;
  }

  .CIRS-G {
    background-color: #23568b;
    color: white;
    padding: 1rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 2rem;
    margin-top: 1rem;
    margin: 20px;
    margin-left: 10px;
    margin-right: 10px;

    h1 {
      text-align: center;
    }
  }

  .instructions {
    color: #2d3e50;
    font-size: 2.2rem;
    margin-bottom: 1rem;
    align-self: center;
    display: flex;
    justify-content: center;
    text-decoration: underline;
    font-family: "Raleway", sans-serif;
    font-weight: 800;
  }

  .strategytitle {
    display: flex;
    justify-content: center;
    font-family: "Raleway", sans-serif;
  }

  .strategy {
    margin-bottom: 2rem;
    margin-left: 5%;
    font-family: "Raleway", sans-serif;
    font-weight: bold;

    h2 {
      color: #2d3e50;
      font-size: 2rem;
      text-decoration: underline;
    }

    span {
      display: block;
      margin-bottom: 0.5rem;
      gap: 10px;
      font-size: 20px;
    }

    @media screen and (max-width: 768px) {
      span {
        font-size: 15px;
      }
    }
  }

  .button_container {
    position: relative;
    .back {
      position: absolute;
      margin: 0 16px;
      font-size: 0.75em;
      font-weight: bold;
      line-height: var(--base-line-height, 1.8em);
      border: none;
      min-width: 100px;
      cursor: pointer;
      padding: 0.3em 2em;
      border-radius: 2px;
      background-color: #1ab394;
      color: white;
      bottom: 25px;
    }
  }

  .sv_qstn {
    font-size: 18px;
    font-weight: bold;
    margin-right: 20px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      text-align: center;
      font-size: 15px;
    }
  }
  .sv_q_m_cell {
    line-height: 1.5;
    list-style: none;
  }

  .sv_q_matrix {
    width: 200px !important;
  }
  // .sv_q_matrix tr {
  //   background: red!important;
  // }

  // .sv_q_m_label {
  //   width: 200px!important;
  //   background: red!important   ;
  // }

  .results {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    table {
      border: 1px solid green;
      border-collapse: collapse;
      width: 60%;
      margin-bottom: 2rem;
      th {
        color: #333;
        font-size: 1.1rem;
        padding: 1rem;
        border-bottom: 2px solid #ddd;

        h1 {
          display: flex;
          justify-content: center;
          align-self: center;
        }
      }
      td {
        padding: 1rem;

        &:first-of-type {
          font-weight: 400;
          color: #333;
          width: 60%;
        }
        input {
          width: 60%;
          padding: 0.5rem;
          border-radius: 4px;
          border: 1px solid #ccc;
        }
      }

      .tablerow {
        font-size: 25px;
      }

      .tablerow:nth-of-type(even) {
        background-color: #f2f2f2;
      }
    }
    .ResultsCIRSG th {
      text-align: center;
    }

    .ResultsCIRSG input[type="number"] {
      text-align: center;
      font-size: 22px;
    }
  }

  .copyright {
    font-size: 14px;
    color: #999;
    margin-top: 20px;
    text-align: center;
    padding: 10px 0;

    &:hover {
      color: #555;
    }
    border-bottom: 1px solid #ddd;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.desc {
  font-size: 2em;
  font-weight: 800;
  margin: 60px;
  margin-bottom: 10px;
  padding: 5px;
  background-color: #ffffff;
  font-family: "Open Sans", sans-serif;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    font-size: 18px;
  }
}
